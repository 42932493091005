<template>
  <div>
    <div class="flex items-center justify-end gap-8">
      <AdminMenu v-if="isAdmin" data-test="icon-admin" />

      <UserIconAndSubMenu v-else />

      <CartIconAndBubble data-test="icon-cart" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'

import AdminMenu from './AdminMenu.vue'
import CartIconAndBubble from './CartIconAndBubble.vue'
import UserIconAndSubMenu from './UserIconAndSubMenu.vue'

const userStore = useUserStore()
const isAdmin =
  userStore.user.isStaff ||
  userStore.user.isShadowingClient ||
  userStore.user.isShadowingMerchant
</script>
